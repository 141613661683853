.no-padding {
    padding: 0 !important;
}

.coloredbox {
    background-color: #eeffe4;
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    padding: 4.8rem;
    height: 100%;
}

.nocoloredbox {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mcontainer {
    width: 100%;
    height: 100%;
}

.MuiDialog-paper {
    max-width: 60vw !important;
}

.logowtext {
    width: 10em !important;
    height: 2.5em !important;
}

.grey-text {
    font-style: normal;
    font-size: 0.9rem !important;
    line-height: 1.6rem;
    color: #8d99a2;
    text-align: center;
    width: 100%;
}

.special-label {
    display: none;
}

.react-tel-input .country-list {
    height: 8vh !important;
    max-height: 8vh !important;
    width: 20vw !important;
}

.react-tel-input .form-control {
    width: 100% !important;
}


.align-center {
    text-align: center;
}

.error-text {
    font-style: normal;
    font-size: 0.8rem !important;
    line-height: 1.6rem;
    color: red;
    text-align: center;
    width: 100%;
}

.go-back {
    font-style: normal;
    font-size: 1rem !important;
    line-height: 1.6rem;
    color: #0FA739;
    text-align: center;
    margin-right: 3em !important;
    align-self: center;
    cursor: pointer;
}

.linear-prog {
    position: absolute !important;
    height: 4px;
    background-color: rgb(255 255 255);
    width: 50%;
    top: 0 !important;
}

.otp-box {
    display: grid;
    align-items: center;
    justify-content: center;
    margin-bottom: 4em !important;
}

.logged-in {
    color: #0FA739;
    text-align: center;
    font-weight: bold !important;
    font-size: 1em !important;
    align-self: center;
}

.image {
    width: 14em !important;
    height: 15em !important;
    cursor: pointer
}

.signupform {
    padding: 4em;
    margin-left: 0
}

.firstform {
    margin-left: 0 !important
}

.green-title-box {
    display: block;
    align-items: center;
}

.green-input-title {
    font-size: 0.9em !important;
    color: #0FA739;
    font-family: 'Open Sans' !important;
    line-height: 26px !important;
    font-weight: 700 !important;
    text-align: center;
}

.first-line {
    font-family: 'League Spartan', sans-serif !important;
    font-weight: 700 !important;
    font-size: 1.2em !important;
    text-align: center;
}

.second-line {
    font-family: 'League Spartan', sans-serif !important;
    font-weight: 700 !important;
    font-size: 2.5em !important;
    text-align: center;
}

.third-line {
    font-family: 'League Spartan', sans-serif !important;
    font-weight: 700 !important;
    font-size: 1.8em !important;
    text-align: center;
}

.credit-line {
    font-family: 'Nunito' !important;
    font-size: 1em !important;
    font-weight: 300 !important;
}

@media (max-width: 768px) {
    .MuiDialog-paper {
        max-width: 80vw !important;
    }

    .coloredbox {
        background-color: #eeffe4;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        height: 100%;
    }

    .image {
        width: 4em !important;
        height: 4em !important;
        cursor: pointer
    }

    .signupform {
        padding: 2em;
        margin-left: 0
    }

    .firstform {
        margin-left: 0 !important
    }

    .linear-prog {
        width: 100%;
    }

    .react-tel-input .country-list {
        width: 55vw !important;
    }

    .first-line {
        font-size: 0.9em !important;
    }

    .second-line {
        font-size: 1.2em !important;

    }

    .third-line {
        font-size: 1em !important
    }

}