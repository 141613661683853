.part-full-page{
    min-height: 95vh;
    display: flex;
    flex-direction: column;
    z-index: -1;
}
.part-text-box{
    padding-top: 5rem;
    text-align: center;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 3rem;
}
.btn{
    align-self: center;
    margin-top: 12vh;
    position: absolute;
    bottom:4vmax
}
.partners {
    max-width: 25vw;
    align-self: center;
    z-index: -1;
    padding-top: 2rem;
  }
.btn-yellow{
    padding-top: 7vh;
    align-self: center;
}

.part-card1{
    max-width: 23vmax;
    position: absolute;
    top:17vmax;
    right:15vmax;
}
.part-card2{
    max-width: 25vmax;
    position: absolute;
    top:65vh;
    left:23vw;
}


@media (max-width:768px) {
    .part-text-box{
        width: 20vmax;
        padding-left: 1em;
        text-align: start;
    }
    .partners {
        max-width: 50vw;
    }
    .btn-yellow{
        padding-top: 10vh;
    }

    .part-card1{
        max-width: 80%;
        top:4vmax;
        left: 0;
        align-self: center;
        position: relative;
    }
    .part-card2{
        top:5vmax;
        left: 0;
        align-self: center;
        position: relative;
        max-width: 80%;
    }
    .btn{
        margin-top: 1vh;
    }
}