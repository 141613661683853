.privacy-policy-container {
background-color: #fff9ec;
padding: 10vw;
padding-top: 5vh;
}
.header{
    font-family: 'Nunito' !important;
    font-size: 1.5em !important;
    font-weight: bold !important;
}
.textStyle{
    font-family: 'Nunito' !important;
    font-size: 1em !important;
    font-weight: 100 !important;
}