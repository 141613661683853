.floating-button-div {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100;
}


.fb {
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 50%;
    padding: 20px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}