.fp-main-text-box {
  padding-top: 10rem;
  width: 40vmax;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  line-height: 3rem;
  color: #3f3232;
  z-index: 100;
}

.first-box {
  padding-top: 10rem;
}

.img-box {
  display: flex;
  align-items: end;
  justify-content: center;
}

.img-box2 {
  display: flex;
  align-items: end;
  justify-content: center;
  margin: 4em;
}

.sp-main-text-box {
  align-items: center;
  text-align: center;
  width: 100%;
  font-family: "Nunito";
  font-weight: 700;
  font-size: 2rem;
  line-height: 2rem;
  color: #3f3232;
}

.sp-text-box {
  margin-top: -4rem;
}

.align-text-center {
  text-align: center;
  margin-bottom: 1rem;
  /* width: auto !important; */
}

.margin-bottom {
  margin-bottom: 2rem;
}

.sp-secondary-text-box {
  align-items: center;
  text-align: center;
  width: 100%;
  font-family: "Nunito";
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 3rem;
  color: #0FA739;
  /* padding-top: 0.5em; */
}


.text-box {
  padding-top: 3rem;
  width: 40vmax;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.6rem;
  color: #8d99a2;
}

.text-box2 {
  padding-top: 3rem;
  width: 40vmax;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.6rem;
  color: #8d99a2;
  margin: 4em;
}

.right-blob {
  position: absolute;
  max-width: 38vmax;
  right: 0px;
  top: 0px;
  z-index: -2;
}

.left-blob {
  position: absolute;
  max-width: 60vw;
  max-height: 100vh;
  /* z-index: -1; */
}

.heart-left-blob {
  position: absolute;
  max-width: 60vw;
  max-height: 80vh;
}

.txt-green {
  color: #0FA739 !important;
}

.txt-green-bottom {
  color: #0FA739 !important;
  cursor: pointer;
}

.full-page1 {
  min-height: 65vh;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto
}

.full-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.less-full-page {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.btn-yellow {
  margin-top: 5vh !important;
}

.food-bowl-1 {
  position: absolute;
  max-width: 24vw;
  right: 10vmax;
  top: 9vmax;
  z-index: -1;
}

.food-bowl-2 {
  position: absolute;
  max-width: 14vmax;
  left: -2vw;
  top: -7vh;
  transform: rotate(90deg);
}

.food-bowl-3 {
  position: absolute;
  top: 5vh;
  max-width: 25vw;
  z-index: 50;
}

.brocol {
  position: absolute;
  max-width: 28vw;
  bottom: 6vmax;
  left: 0;
}

.chalk-board {
  position: absolute;
  max-width: 30vmax;
  right: 5vmax;
  top: 5vmax;
  z-index: -1;
}

.cards-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.img-bg {
  background-color: #eeffe4;
  flex-direction: row;
  align-items: center;
  display: inline-flex;
  border-radius: 4em;
  padding: 0.5em;
  margin-bottom: 1rem !important;

}

.img-bg-number {
  background-color: #eeffe4;
  flex-direction: row;
  align-items: center;
  display: inline-flex;
  border-radius: 1em;
  font-size: 4em;
  font-family: "Open Sans" !important;
  color: #0FA739;
  padding: 2rem;
  width: 4rem;
  height: 4rem;
  border-radius: 4rem;
  justify-content: center;
}

/* .card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 18vw;
  max-width: 18vw;
  text-align: center;
  min-height: 30vh;
} */

.card-icon {
  /* margin: 1em; */
  width: 3vw;
  height: 3vw;
}

.card {
  margin: 1rem;
  box-shadow: 0 5px 30px -10px rgba(0, 0, 0, .1) !important;
  border-radius: 20px;
  display: flex;
  text-align: center;
  border-radius: 1em !important;
  background-color: white !important;
  max-width: 18vw;
  min-height: 17.5vw;
  align-items: center;
}

.card-title {
  font-family: "Open Sans" !important;
  font-weight: bold !important;
  line-height: 27.28px !important;
  font-size: 1.2em !important;
  color: rgb(63, 50, 50, 1) !important;
}

.card-description {
  padding: 1em;
  font-weight: 400 !important;
  font-family: "Open Sans" !important;
  font-size: "16px" !important;
  line-height: 26px;
  letter-spacing: 0em;
  color: #8d99a2;
}

.background-pale-yellow {
  background-color: #fff9ec;
}

.background-pale-green-left {
  background-color: #eeffe4;
  border-radius: 0 0 0 450px;
  align-items: center;
  justify-content: center;
  min-height: fit-content;
  padding: 240px 300px 240px 300px;

}

.background-pale-green-right {
  background-color: #eeffe4;
  border-radius: 0 0 450px 0;
  /* z-index: -5; */
  /* align-items: center;
  justify-content: center;
  min-height: fit-content;
  padding: 240px 300px 240px 300px; */

}

.tp-main-text-box {
  width: 100%;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  line-height: 3rem;
  color: #3f3232;
  /* z-index: 100; */
  /* text-align: right; */
}

.home-text-box {
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 2;
  margin-right: 10em;
  margin-left: 10em;
  margin-top: 3em;
}

.home-text-box2 {
  width: 60%;
  margin-top: 5rem;
  align-self: flex-start;
  margin-left: 10em;
}

.home-text-box3 {
  width: 50%;
  margin-top: 5rem;
  padding-right: 5rem;
  align-self: flex-end;
  z-index: 100;
}

.padded-box {
  z-index: 1;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  color: #3f3232;
  text-align: center;
  margin-top: 1em;
}

.plansimg {
  width: 18rem;
  align-self: center;
}

.title-box {
  font-family: "Open Sans" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 1rem;
  color: #3f3232;
  padding-top: 2rem;
  padding-bottom: 3rem;
  line-height: 28px !important;
  letter-spacing: 0em !important;
}

.text {
  padding-bottom: 2rem;
  font-family: "Open Sans" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  color: rgba(63, 50, 50, 1);
  font-size: 1em !important;
  z-index: 100;


}

.bullet {
  font-size: 0.6rem;
  padding-right: 1rem;
}

.know-more {
  font-family: "Nunito" !important;
  font-style: underline !important;
  font-weight: bold !important;
  padding-left: 2rem;
  text-decoration: none;
  position: relative;
  white-space: nowrap;
}

.know-more:after {
  content: "";
  width: 73%;
  position: absolute;
  left: 2rem;
  bottom: 0.001px;
  border-width: 0 0 1px;
  border-style: solid;
}

.impact-title {
  font-family: "Nunito";
  font-style: normal;
  font-weight: bold !important;
  font-size: 2.2rem;
  color: #3f3232;
  margin-top: 5rem;
  align-self: center;
}

.impact-container {
  align-self: center;
  margin-top: 2em;
}

.impact-item1 {
  text-align: center;
  background-color: rgba(255, 249, 236, 1);
  padding: 2em;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
  justify-content: center;
}

.impact-item2 {
  text-align: center;
  background-color: rgba(255, 240, 207, 1);
  padding: 2em;
  align-items: center;
  justify-content: center;
}

.impact-item3 {
  text-align: center;
  background-color: rgba(255, 229, 173, 1);
  padding: 2em;
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
  justify-content: center;
}

.impact-item-title {
  font-family: "Nunito" !important;
  font-weight: 800 !important;
  font-size: 2.2rem !important;
  color: #3f3232;
  line-height: 49px !important;
  letter-spacing: 0em !important;
}

.impact-item-subtitle {
  font-family: "Nunito" !important;
  font-weight: 700 !important;
  font-size: 1.2rem !important;
  color: #3f3232 !important;
  line-height: 29px !important;
  letter-spacing: 0em !important;
}

.home-bottom-text {
  font-family: "Open Sans" !important;
  font-weight: 600 !important;
  font-size: 1.1em !important;
  margin-left: 5vw !important;
  padding-bottom: 1rem;
}

.bottom-item {
  align-self: center;
  margin-top: 0 !important;
}

.home-bottom-item {
  align-self: end;
}

.phone {
  object-fit: cover;
}

.restaurants {
  max-width: 6vmax;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.step-icon {
  width: 18rem;
  margin: 2rem
}

.half-page {
  min-height: 55vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.get-started {
  flex: 1;
}

.input-box {
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .padded-box {
    text-align: center;
  }

  .cards-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
  }

  .card {
    max-height: 40vh;
    min-width: 70vw;
    max-width: 80vw;
  }

  .card-content {
    max-width: 100%;
    text-align: center;
  }

  .sp-main-text-box {
    font-size: 1.2rem;
  }

  .sp-secondary-text-box {
    font-size: 2rem;
  }

  .left-blob {
    width: 100%;
    max-width: 35vw;
  }

  .right-blob {
    max-width: 30vw;
  }

  .heart-left-blob {
    max-width: 35vw;
    bottom: 0;
  }

  .tp-main-text-box {
    font-size: 1.8rem;
    margin-right: 0.2rem;
  }

  .card-icon {
    margin: 1em;
    width: 10vw;
  }

  .home-text-box {
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .home-text-box2 {
    width: 70vw;
    margin-left: 2em;
  }

  .home-text-box3 {
    width: 70vw;
    padding-right: 0;
    margin-right: 2em;
  }

  .chalk-board {
    max-width: 10vmax;
    right: 1vmax;
  }

  .brocol {
    bottom: 4vmax;
  }

  .title-box {
    text-align: center;
  }

  .text {
    text-align: center;
  }

  .btn-yellow {
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin-top: 1em !important;
    align-self: center;
    justify-self: center;
    text-align: center;
    margin-left: 0 !important;
  }

  .know-more {
    display: none;

  }

  .impact-container {
    flex-direction: column !important;
    align-items: center;
    margin-bottom: 1em;
  }

  .impact-item1 {
    max-width: 80% !important;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    border-bottom-left-radius: 0;
  }

  .impact-item2 {
    max-width: 80% !important;
  }

  .impact-item3 {
    max-width: 80% !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 1em;
  }

  .sp-text-box {
    margin-top: 5vmax;
  }

  .full-page1 {
    min-height: 100vh;
  }

  .food-bowl-2 {
    top: -14vh;
    left: -10vw;
  }

  .bottom-item {
    margin-top: 7vh !important;
    margin-bottom: 5vh !important;
  }

  .restaurants {
    max-width: none;
  }

  .first-img {
    max-width: 75vw;
  }

  .img-box {
    margin-left: 0;
  }

  .text-box2 {
    padding-top: 3rem;
    width: 80% !important;
  }

  .background-pale-green-left {
    padding: 0% !important;
  }

  .align {
    display: grid;
    justify-content: center;
    margin-bottom: 4em;
  }

  .align-text-center {
    text-align: center;
    width: auto;
  }
    /* Slideshow */
   
}