.foodie-full-page{
    min-height: 95vh;
    display: flex;
    flex-direction: column;
    z-index: -1;
}
.foodie-text-box{
    padding-top: 5rem;
    text-align: center;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 3rem;
}
.fbtn{
    align-self: center;
    margin-top: 12vh;
    position: absolute;
    bottom:4vmax
}
.street-food {
    max-width: 35vw;
    align-self: center;
    z-index: -1;
    padding-top: 4rem;
  }
.btn-yellow{
    padding-top: 7vh;
    align-self: center;
}

.info-card1{
    max-width: 23vmax;
    position: absolute;
    top:15vmax;
    left:16vmax;
}
.info-card2{
    max-width: 25vmax;
    position: absolute;
    top:19.5vmax;
    right:15vmax;
}
.info-card3{
    max-width: 24vmax;
    position: absolute;
    top:33vmax;
    left:24.5vmax;
}
.info-card4{
    max-width: 18vmax;
    position: absolute;
    top:31vmax;
    right:17vmax;
}

@media (max-width:768px) {
    .foodie-text-box{
        width: 20vmax;
        padding-left: 1em;
        text-align: start;
    }
    .street-food {
        max-width: 55vw;
        padding-top: 2rem !important;
    }
    .btn-yellow{
        padding-top: 10vh;
    }

    .info-card1{
        top:4vmax;
        left: 0;
        align-self: center;
        position: relative;
        max-width: 80%;
    }
    .info-card2{
        top:5vmax;
        left: 0;
        align-self: center;
        position: relative;
        max-width: 80%;
    }
    .info-card3{
        top:6vmax;
        left: 0;
        align-self: center;
        position: relative;
        max-width: 80%;
        margin: 0;
    }
    .info-card4{
        top:7vmax;
        left: 0;
        align-self: center;
        position: relative;
        max-width: 80%;
    }
    .fbtn{
        margin-top: 15vh;
        margin-bottom: 0.5vh;
        position: relative;
    }
}