.coupon-tag {
    background-color: #ffd700;
    padding: 20px;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    width: 200px; 
    margin: 10px;
  }
  
  .coupon-code {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .coupon-description {
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .coupon-value {
    font-size: 24px;
    font-weight: bold;
  }

  .coupons-container {
    display: flex;
    flex-wrap: wrap;
  }