.res-full-page{
  min-height: 95vh;
  display: flex;
  flex-direction: column;
}
.res-text-box{
    padding-top: 5rem;
    text-align: center;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 3rem;
}
.res-text-box2 {
    padding-top: 5rem;
    text-align: center;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
  }
.btn{
    align-self: center;
    margin-top: 12vh;
    position: absolute;
    bottom:4vmax
}
.eating-together {
    max-width: 25vmax;
    align-self: center;
    z-index: -1;
    padding-top: 2rem;
  }
.btn-yellow{
    padding-top: 7vh;
    align-self: center;
}

.res-card1{
    max-width: 24vmax;
    position: absolute;
    top:15vmax;
    left:14vmax;
}
.res-card2{
    max-width: 20vmax;
    position: absolute;
    top:17vmax;
    right:18vmax;
}
.res-card3{
    max-width: 25vmax;
    position: absolute;
    top:32vmax;
    left:23vmax;
}
.res-card4{
    max-width: 19vmax;
    position: absolute;
    top:30vmax;
    right:16vmax;
}
.res-text{
    text-align: center;
    font-family: "Open Sans";
    color: rgb(92, 90, 90);
    padding-top: 1.5rem;
}
.profit-table{
    /* box-shadow: none !important; */
    border-width:0 ;
}
.title-text{
    padding: 1rem;
    font-family: "Open Sans" !important;
    font-size: 1.1em !important;
    font-weight: bold !important;
}
.grey-title{
    color: grey !important;
    font-family: "Open Sans" !important;
}
.darkgrey-title{
    color: rgb(116, 115, 115) !important;
    font-family: "Open Sans" !important;
    font-size: 0.85em !important;
    font-weight: 400 !important;
}
.dark-title{
    color:  rgba(100, 100, 100, 1) !important;
    font-family: "Open Sans" !important;
    font-size: 0.85em !important;
    font-weight: bold !important;
    line-height: 26px;
}
.values{
    font-weight: bold !important;
    font-family: "Open Sans" !important;
}

.grid-container{
    align-self: center;
}

.input-title{
    font-size: 0.7em !important;
    color: grey;
    width: fit-content;
    font-family: 'Open Sans' !important;
    line-height: 26px !important;
    font-weight: 400 !important;

}
.input-container{
    padding: 2vh;
}
.input-text{
    width: 17vmax !important;
    outline-color: rgba(236, 236, 236, 1);
}
.input-text2{
    width: 15.7vmax !important;
}
.MuiOutlinedInput-input{
    padding-top: 0.5em !important;
    padding-bottom: 0.5em !important;
}


.ybtn{
    padding-left: 1em;
    padding-top: 1em
}
.half-page{
    min-height: 55vh;
    display: flex;
    flex-direction: column;
}
.phone{
    max-height: 40vh;
}
.bottom-container{
    justify-content: center;
}
.bottom-item{
    margin-top: 7vh !important;
}
.bottom-item2{
    margin-top: 7vh !important;
}
.bottom-text{
    font-family: 'Nunito' !important;
    font-weight: bold !important;
    font-size: 1.1em !important;
    margin-left: 5vw !important;
    width: 40vw;
}
.bottom-input-box{
    margin-left: 5vw !important;
    margin-top: 2vw !important
}
.bottom-btn{
    margin-left: 5vw ;
    margin-top: 1vw 
}
@media (max-width:768px) {
    .res-text-box{
        width: 20vmax;
        padding-left: 1em;
        text-align: start;
    }
    .eating-together {
        max-width: 50vw;
    }
    .btn-yellow{
        padding-top: 10vh;
    }
    .res-text{
        margin-left: 2vw;
    }
    .res-card1{
        top:4vmax;
        left: 0;
        align-self: center;
        position: relative;
        max-width: 80%;
    }
    .res-card2{
        top:5vmax;
        left: 0;
        align-self: center;
        position: relative;
        max-width: 80%;
    }
    .res-card3{
        top:6vmax;
        left: 0;
        align-self: center;
        position: relative;
        max-width: 80%;
        margin: 0;
    }
    .res-card4{
        top:7vmax;
        left: 0;
        align-self: center;
        position: relative;
        max-width: 80%;
    }
    .btn{
        margin-top: 15vh;
        margin-bottom: 0.5vh;
        position: relative;
    /* bottom:6vmax */
    }
    .grid-container{
        flex-direction: column !important;
    }
    .grid-item{
        max-width: 100% !important;
    }
    .input-container{
        flex-direction: column !important;
    }
    .input-item{
        max-width: 100% !important;
        align-self: center;
        text-align: center;
    }
    .input-title{
        align-self: center;
        width: auto;
    }
    .title-text{
        text-align: center;
    }
    .ybtn{
        align-items: center;
        text-align: center;
        padding-bottom: 5vh;
        padding-left: 0;
    }
    .bottom-text{
        width: 100%;
        text-align: center;
        margin-left: 0 !important;
    }
    .bottom-input-box{
        margin-left: 0 !important;
        text-align: center;
    }
    .bottom-btn{
        margin-left: 0 !important;
        text-align: center;
        margin-top: 2vh;
    }
    .bottom-item2{
        display: none;
    }
}