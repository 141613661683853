@use 'sass:math';

$n: 9;
/* number of images */

.gallery {
    --s: 400px;
    /* control the size */
    --d: 30s;
    /* animation duration */
    --delay-between-animations: 30s;
    /* delay between animations */

    display: grid;
    width: var(--s);
    aspect-ratio: 1;
    overflow: hidden;
    padding: calc(var(--s) / 20);
    border-radius: 50%;
    position: relative;
    clip-path: circle(49.5%);
    /* to avoid a few glitches caused by overflow: hidden */
}

.gallery::after {
    content: "";
    position: absolute;
    inset: 0;
    padding: inherit;
    border-radius: inherit;
    // background: repeating-conic-gradient(#789048 0 30deg, #DFBA69 0 60deg);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.gallery>img {
    grid-area: 1/1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
    transform-origin: 50% (50% / math.sin(180deg / $n) + 50%);
}

.gallery::after,
.gallery>img {
    animation: m var(--d) infinite cubic-bezier(.5, -0.2, .5, 1.2);
}

@for $i from 2 to ($n + 1) {
    .gallery>img:nth-child(#{$i}) {
        animation-delay: calc((1 - $i) / $n * var(--d) + (1 - $i) / $n * var(--delay-between-animations));
    }
}

@keyframes m {

    0%,
    3% {
        transform: rotate(0);
    }

    @for $i from 1 to $n {

        #{($i / $n) * 100 - 2}%,
        #{($i / $n) * 100 + 3}% {
            transform: rotate(#{($i / $n) * -360}deg);
        }
    }

    98%,
    100% {
        transform: rotate(-360deg);
    }
}