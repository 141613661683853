.blogs-full-page{
    min-height: 95vh;
    display: flex;
    flex-direction: column;
    z-index: -1;
}
.blogs-text-box{
    padding-top: 5rem;
    text-align: center;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 3rem;
}
.blogbtn{
    align-self: center;
    margin-top: 12vh;
    position: absolute;
    bottom:4vmax

}
.blogging {
    max-width: 35vmax;
    align-self: center;
    z-index: -1;
    padding-top: 2rem;
  }
.btn-yellow{
    padding-top: 7vh;
    align-self: center;
}


.blogs-card2{
    position: absolute;
    bottom:13vmax;
    left:24vmax;
    max-width: 25vw;
}
.blogs-card1{
    position: absolute;
    top:14vmax;
    right:16vmax;
    max-width: 23vw;
}



@media (max-width:768px) {
    .blogs-text-box{
        width: 20vmax;
        padding-left: 1em;
        text-align: start;
    }
    .blogging {
        max-width: 60vw;
        padding-top: 3.5rem;
    }
    .btn-yellow{
        padding-top: 10vh;
    }
    
    .blogs-card1{
        top:4vmax;
        left: 0;
        align-self: center;
        position: relative;
        max-width: 80%;
        margin: 0;

    }
    .blogs-card2{
        top:5vmax;
        left: 0;
        align-self: center;
        position: relative;
        max-width: 80%;
        margin: 0;
    }
    .blogbtn{
        margin-top: 15vh;
        margin-bottom: 0.5vh;
        position: relative;
    }
}