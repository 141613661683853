.mainContainer {
    /* background-color: #fff9ec; */
    /* padding: 10vw; */
    padding-top: 5vh;
}

.header {
    font-family: 'Nunito' !important;
    font-size: 1.5em !important;
    font-weight: bold !important;
}

.textStyle {
    font-family: 'Nunito' !important;
    font-size: 1em !important;
    font-weight: 100 !important;
}
.categoryTitle {
    flex: 1;
    font-size: 1.2em !important;
    text-transform: capitalize;
    font-Family: 'Nunito' !important;
    color: black
  }
.image {
    width: 4em;
    height: 4em;
    border-radius: 1em;
    margin-right: 1em;
    margin-left: 1em;
}
.price{
    font-size: 0.95em !important;
    font-family: 'Nunito' !important;
    max-width: 80%;
}
.description{
    font-size: 0.9em !important;
    max-width: 80%;
}
.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-family: 'Nunito' !important;
    font-size: 0.85em !important;
    margin-left: 0.5em;
}
.storeName {
    font-family: 'Nunito' !important;
    font-size: 1.5em !important;
    font-weight: bold !important;
    text-align: center ;
}
.storeImage {
    height: 3em;
    width: 3em;
}
.storeBox {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}