.wrapper {
  max-width: 80% !important;
  margin-left: 10em;
  margin-top: 3em;
  margin-bottom: 2em;
}

.linkStyle {
  background-color: none;
  text-transform: none !important;
  font-family: "Nunito" !important;
  color: black !important;
  font-size: 0.8rem;
  justify-content: left;
  min-width: 0 !important;
  font-weight: 700 !important;
}

.linkStyle2 {
  background-color: none;

  text-transform: none !important;
  font-family: "Open Sans" !important;
  color: rgba(63, 50, 50, 1) !important;
  font-size: 0.8rem;
  letter-spacing: -0.2 !important;
  font-weight: 700 !important;
}

.titleStyle {
  font-family: "Nunito" !important;
  color: #0FA739;
  font-size: 1rem !important;
  margin-left: 0.4rem !important;
  font-weight: 700 !important;
}

.container {
  display: flex;
  justify-content: center !important;
  /* justify-content: flex-end !important; */
  /* margin-right: 20; */
}

.copyright {
  font-family: "Open Sans" !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  letter-spacing: -0.20454545319080353px !important;
  text-align: left;
  color: #646464;
}

.linkBox {
  width: 120px;
}

.bottomText {
  font-family: "Open Sans" !important;
  font-size: 0.9em !important;
  font-weight: 400 !important;
  line-height: 26px !important;
  letter-spacing: -0.20454545319080353px !important;
}

@media (max-width: 768px) {
  .wrapper {
    flex-direction: column !important;
    /* width: '100%'; */
    justify-content: center;
    margin-left: 0;
    align-items: center;
    max-width: none !important;
  }

  .container {
    margin-top: 5em !important;
    align-self: center;
    text-align: center;
    max-width: 80% !important;
    /* justify-content: flex-end !important; */
  }

  .linkStyle {
    /* display: inline !important;  */
    align-self: center;
    text-decoration: 'none' !important
  }



  .titleStyle {
    text-align: center;
  }

  .linkBox {
    /* width: 130px !important; */
    justify-content: center;
    text-align: center;
  }

  .phone {
    display: none;
  }

  .bottomText {
    text-align: "center";
    align-self: center;
  }

  .copyright {
    text-align: center;
    margin-bottom: 1em !important;
    max-width: 100% !important;
  }

  .bottomItem {
    max-width: 100% !important;
  }

  .home-bottom-text {
    text-align: center;
  }

  .home-bottom-item {
    display: none;

  }

  .bottom-logo {
    width: 100% !important;
  }
}